<template>
  <div class="wrap">
    <!--顶部面包靴-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/volunteer/volunteerManage' }" class="fs20">志愿者管理</el-breadcrumb-item>
      <el-breadcrumb-item class="fs20">{{type===1?'新增':type===2?'编辑':'详情'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--表单-->
    <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="rule-form">
      <div class="title">团体基础信息</div>
      <el-row>
        <el-form-item label="志愿者姓名" prop="volunteerName">
          <el-input v-model="ruleForm.volunteerName" class="w230" placeholder="请输入" :disabled="type===2||type===3">
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNumber">
          <el-input v-model="ruleForm.idNumber" class="w230" placeholder="请输入" :disabled="type===2||type===3"
            @blur="setBirthday(ruleForm.idNumber)"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker type="date" placeholder="请选择" value-format="yyyy-MM-dd" v-model="ruleForm.birthday"
            class="w230" :disabled="disabledFlag">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="ruleForm.gender" placeholder="请选择" class="w230" :disabled="disabledFlag">
            <el-option v-for="item in SEXLIST" :key="item.value" :label="item.label" :value="item.value+''"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="手机号" prop="telephone">
          <el-input v-model="ruleForm.telephone" class="w230" placeholder="请输入" :disabled="disabledFlag"></el-input>
        </el-form-item>
        <el-form-item label="家庭住址" prop="address">
          <el-input type="textarea" v-model="ruleForm.address" placeholder="请输入" class="w230" :disabled="disabledFlag">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="加入团体" prop="volunteerGroupId">
          <el-select v-model="ruleForm.volunteerGroupId" multiple placeholder="请选择" class="w230"
            :disabled="disabledFlag || accountType==='1'">
            <el-option :label="item.groupName" :value="item.id" v-for="item in groupList" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="证件照" prop="idPhoto">
          <el-image v-if="type===3" class="img-wrap" :src="ruleForm.idPhoto" fit="fit"
            @click="seeImage(ruleForm.idPhoto)"></el-image>
          <UploadAvatar v-else @getImgUrl="(val)=>ruleForm.idPhoto=val" url="/volunteerManage/uploadImg"
            :imgUrl="ruleForm.idPhoto" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="相关资料" prop="attachment">
          <UploadFile @getData="getFileList" :attachmentList="info.attachmentList" :type="type"/>
        </el-form-item>
      </el-row>
      <el-form-item class="btns">
        <el-button @click="back">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-if="type!==3">{{type===1?'提交':'修改'}}</el-button>
      </el-form-item>
    </el-form>
    <!-- 查看图片弹窗 -->
    <ImgaeDialog v-if="imageVisible" :imageVisible.sync="imageVisible" :imagesList="imagesList" />
  </div>
</template>

<script>
import { IdCard } from '@/utils'
import { addVolunteer, groupNameList, updateVolunteer } from '@/api/volunteerManage'
import UploadAvatar from '@/components/UploadAvatar'
import UploadFile from './components/uploadFile'
import { SEXLIST } from '@/data/dataDictionary'
export default {
  components: {
    UploadAvatar,
    UploadFile
  },
  data() {
    return {
      SEXLIST,
      imageVisible: false,
      imagesList: [],
      ruleForm: {
        address: '',
        attachment: '',
        birthday: '',
        gender: '',
        idNumber: '',
        idPhoto: '',
        telephone: '',
        volunteerGroupId: '',
        volunteerName: ''
      },
      rules: {
        volunteerName: [
          { required: true, message: '请输入志愿者姓名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern:
              /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ],
        volunteerGroupId: [
          { required: true, message: '请选择志愿团体', trigger: 'onchange' }
        ],
        telephone: [
          { required: false, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: '请正确输入11位手机号码',
            trigger: 'blur'
          }
        ]
      },
      groupList: [],
      departmentName: '',
      roleName: '',
      confirmPassword: '',
      info: {},
      type: 1, // 1新增 2编辑 3详情
      disabledFlag: false,
      // 0 非团队管理员 1为团队管理员 string
      accountType: '0'
    }
  },
  mounted() {
    this.accountType = JSON.parse(
      localStorage.getItem('cad_userInfo')
    ).accountType
    this.init()
    this.groupNameList()
  },
  methods: {
    init() {
      this.type = Number(this.$route.query.type)
      if (this.type === 2 || this.type === 3) {
        this.info = JSON.parse(this.$route.query.info)
        Object.assign(this.ruleForm, this.info)
        let id = this.ruleForm.volunteerGroupList.map((item) => item.id + '')
        this.ruleForm.volunteerGroupId = id
        this.confirmPassword = this.info.password
      }
      if (this.type === 3) {
        this.disabledFlag = true
      }
    },
    setBirthday(idNumber) {
      // 500101199203076728
      this.$refs.ruleForm.validateField('idNumber', (res) => {
        // 如果身份证校验通过，才自动填充老人的出生日期
        // 为空表示校验通过
        if (!res) {
          this.ruleForm.birthday = IdCard(idNumber, 1) + ''
          this.ruleForm.gender = IdCard(idNumber, 2) + ''
        }
      })
    },
    getFileList(val) {
      console.log('filelist', val)
      this.ruleForm.attachment = val.join(',')
    },
    async addVolunteer() {
      const data = Object.assign({}, this.ruleForm, {
        volunteerGroupId: this.ruleForm.volunteerGroupId.join(',')
      })
      const res = await addVolunteer(data)
      this.$message.success(res.data)
      this.back()
    },
    async updateVolunteer() {
      const data = Object.assign({}, this.ruleForm, {
        volunteerGroupId: this.ruleForm.volunteerGroupId.join(',')
      })
      delete data.volunteerGroupList
      delete data.attachmentList
      console.log(data)
      const res = await updateVolunteer(data)
      this.$message.success(res.data)
      this.back()
    },
    async groupNameList() {
      const res = await groupNameList()
      this.groupList = res.data
    },
    getImgUrl(url) {
      this.ruleForm.avatar = url
    },
    seeImage(url) {
      const imgsList = [{ url: url }]
      this.imagesList = imgsList
      this.imageVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type === 1) {
            this.addVolunteer()
          } else {
            this.updateVolunteer()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    back() {
      this.$router.push({ path: '/volunteer/volunteerManage' })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #ffffff;
  padding: 20px;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}、
.w230 {
  width: 230px;
}
.fs20 {
  font-size: 14px;
}
.rule-form {
  margin: 30px 0 0 30px;
}
// .el-input, .el-select, .el-date-editor{
//   width: 230px;
// }
.img-wrap {
  width: 120px;
  height: 120px;
  border-radius: 6px;
}
.btns {
  padding-left: 120px;
}
</style>
</style>
